<template>
    <div>
      <v-dialog persistent v-model="showEditCossaVersionDialog" width="700">
        <v-card :loading="loading">
          <v-form
            lazy-validation
            v-model="form"
            ref="newCossaVersionForm"
            :disable="loading"
          >
            <v-card-title>
              <span class="text-h6 mr-2">Edit {{ cossaVersion.version }}</span>
              <v-spacer></v-spacer>
              <v-btn @click="close" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="cossaVersionPayload.version"
                    :rules="[rules.required]"
                    auto-select-first
                    label="*Cossa Version"
                    color="deep-purple"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="editCossaVersion()"
                  :disabled="!form || loading"
                  color="primary"
                >
                  SAVE
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
    </div>
  </template>

<script>
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'

export default {
  props: {
    showEditCossaVersionDialog: { type: Boolean, default: false },
    cossaVersion: {
      type: Object,
      default: () => null
    }
  },

  data: () => ({
    loading: false,
    form: true,
    cossaVersionPayload: {
      cossa_id: null,
      version: ''
    },
    rules: {
      required: (value) => !!value || 'Required.'
    }
  }),

  created () {
    this.copyCossaVersion()
  },

  methods: {
    async editCossaVersion () {
      if (this.$refs.newCossaVersionForm.validate()) {
        this.loading = true
        await apiService
          .put('admin/cossa_version', this.cossaVersionPayload)
          .then((res) => {
            this.loading = false
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'New cossa version added successfully'
            })
            this.$emit('fetchCossaVersion')
            this.close()
          })
          .catch((err) => {
            this.loading = false
            console.log('ERR', err.response)
            Swal.fire({
              icon: 'error',
              title: 'Failed edit cossa version',
              text: err.response.data.message
            })
          })
      }
    },
    copyCossaVersion () {
      this.cossaVersionPayload.cossa_id = this.cossaVersion.id
      this.cossaVersionPayload.version = this.cossaVersion.version
    },

    close () {
      this.$emit('closeEditCossaVersionDialog')
    }
  }
}
</script>

    <style>
  </style>
