<template>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title color="rgba(0, 0, 0, 0)" flat>
            <span class="font-weight-regular">Cossa Versions</span>
            <v-divider class="ml-2 mr-2" vertical></v-divider>
            <v-btn @click="getCossaVersionList" icon>
              <v-icon dark>mdi-sync</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              class="mr-2"
              clearable
              dense
              hide-details
              label="Search cossa version.."
              outlined
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-btn @click="newVersion()" outlined color="primary">
              <v-icon left> mdi-plus </v-icon>
              New Version
            </v-btn>
          </v-card-title>

          <v-card-text class="mb-0 pb-0 mx-0 px-0">
            <v-data-table
              :loading="cossaVersionTableLoading"
              :search="search"
              :headers="headers"
              :items="cossaVersionList"
              mobile-breakpoint="0"
              ref="devicesTable"
              sort-desc
              :items-per-page="20"
              :footer-props="{
                'items-per-page-options': [10, 20, 40, -1],
              }"
            >
              <template v-slot:[`item.version`]="{ item }">
                <span v-if="item.version"> {{ item.version }} </span>
                <v-icon v-else>mdi-minus</v-icon>
              </template>

              <template v-slot:[`item.release_date`]="{ item }">
                <span v-if="item.release_date"> {{ item.release_date }} </span>
                <v-icon v-else>mdi-minus</v-icon>
              </template>

              <template v-slot:[`item.user_added`]="{ item }">
                <span v-if="item.user_added"> {{ item.user_added }} </span>
                <v-icon v-else>mdi-minus</v-icon>
              </template>

              <template v-slot:[`item.notification_status`]="{ item }">
                <v-icon color="green" v-if="item.notification_status === 'Successful'"> mdi-check-bold </v-icon>
                <v-btn color="purple" dark small v-else-if="item.notification_status === 'Pending'" @click="notifyCossa(item)">Notify</v-btn>

                <v-chip dark color="green" v-else-if="item.notification_status === 'Notifying'" small>
                  <v-icon left class="slow-ani" small> mdi-cog </v-icon>
                  <span>Notifying...</span>
                </v-chip>
                <v-chip small v-else dark :color=notificationChipColor(item.notification_status)>
                  {{ item.notification_status }}...
                </v-chip>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex flex-row">
                  <v-btn
                    v-for="(action, idx) in actionButtons"
                    :key="idx"
                    icon
                    @click="action.func(item)"
                  >
                    <v-icon :color="action.color || 'black'">{{
                      action.icon
                    }}</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card-text>

          <new-cossa-version
            v-if="showNewCossaVersionDialog"
            :showNewCossaVersionDialog="showNewCossaVersionDialog"
            @closeNewCossaVersionDialog="closeNewCossaVersionDialog"
            @fetchCossaVersion="fetchCossaVersion"
          ></new-cossa-version>

          <edit-cossa-version
            v-if="showEditCossaVersionDialog"
            :showEditCossaVersionDialog="showEditCossaVersionDialog"
            :cossaVersion="selectedCossaVersion"
            @closeEditCossaVersionDialog="showEditCossaVersionDialog = false"
            @fetchCossaVersion="fetchCossaVersion"
          ></edit-cossa-version>
        </v-card>
      </v-col>
    </v-row>
  </template>

<script>
import converter from '@/mixins/converter'
import newCossaVersion from './NewCossaVersion.vue'
import { mapGetters } from 'vuex'
import get from 'lodash/get'
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'
import EditCossaVersion from './EditCossaVersion.vue'

export default converter.extend({
  computed: {
    ...mapGetters(['cossaVersionList', 'cossaVersionTableLoading', 'nonNotifiedCossaVersionIds']),
    notificationChipColor: function () {
      return function (state) {
        switch (state) {
          case 'Failed':
            return 'red'
          default:
            return 'orange'
        }
      }
    }
  },
  components: { newCossaVersion, EditCossaVersion },
  name: 'cossaVersion',
  data () {
    return {
      showNewCossaVersionDialog: false,
      showEditCossaVersionDialog: false,
      selectedCossaVersion: {},
      search: '',
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Version', value: 'version' },
        {
          text: 'Release Date',
          value: 'release_date',
          align: 'center'
        },
        { text: 'User Added', value: 'user_added', align: 'center' },
        {
          text: 'Notified Cossa',
          value: 'notification_status',
          align: 'center'
        },
        { text: 'Actions', value: 'actions', align: 'center' }
      ],
      actionButtons: [
        {
          icon: 'mdi-circle-edit-outline',
          color: 'grey',
          func: this.editCossaVersion
        },
        {
          icon: 'mdi-delete-outline',
          color: 'red',
          func: this.deleteCossaVersion
        }
      ]
    }
  },
  mixins: [converter],
  mounted () {
    this.getCossaVersionList()
  },
  methods: {
    async notifyCossa (item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'All Client will be notified about this cossa version.',
        icon: 'question',
        showCancelButton: true
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const cossaVersionIds = [item.id]
            const notificationConfig = await this.$store.dispatch('notifyClients', { notifyCossaVersion: true, nonNotifiedCossaVersionIds: cossaVersionIds })
            notificationConfig.status = 'Notifying'
            await this.$store.dispatch('updateNotificationStatus', { body: notificationConfig })
            if (this.nonNotifiedCossaVersionIds.length > 0 && this.nonNotifiedCossaVersionIds.includes(item.id)) {
              this.$store.dispatch('removeNonNotifiedCossaVersion', item.id)
            }
            this.getCossaVersionList()
            Swal.fire('Success!', 'Started notifying process.', 'success')
          } catch (error) {
            console.log('Error while notifying cossas: ', error.message)
            Swal.fire(
              'Warning!',
              'Notifying process cannot be started.',
              'warning'
            )
          }
        }
      })
    },
    async getCossaVersionList () {
      this.$store.dispatch('getCossaVersion')
    },

    newVersion () {
      this.showNewCossaVersionDialog = true
    },
    closeNewCossaVersionDialog () {
      this.showNewCossaVersionDialog = false
    },
    fetchCossaVersion () {
      this.getCossaVersionList()
      this.showNewCossaVersionDialog = false
    },
    editCossaVersion (item) {
      this.selectedCossaVersion = item
      this.showEditCossaVersionDialog = true
    },
    deleteCossaVersion (item) {
      Swal.fire({
        title: 'Are you sure?',
        text: `This "${item.version}" cordatus cossa version will be delete.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, do it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await apiService
            .delete(`admin/cossa_version/${item.id}`)
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Deleted',
                text: 'Cordatus cossa version deleted successfully!'
              })
              this.getCossaVersionList()
            })
            .catch((err) => {
              Swal.fire({
                icon: 'error',
                title: 'Error while deleted cordatus cossa version.',
                text: get(err, 'response.data.message', 'Something went wrong')
              })
            })
        }
      })
    }
  }
})
</script>

  <style>

  .slow-ani {
    -webkit-animation: rotation 6s infinite linear;
    animation: rotation 6s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  </style>
