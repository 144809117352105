<template>
    <div>
      <v-dialog persistent v-model="showNewCossaVersionDialog" width="700">
        <v-card :loading="loading">
          <v-form
            lazy-validation
            v-model="form"
            ref="newCossaVersionForm"
            :disable="loading"
          >
            <v-card-title>
              <span class="text-h6">New Cossa Version</span>
              <v-spacer></v-spacer>
              <v-btn @click="close" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="cossaVersionPayload.version"
                    :rules="[rules.required]"
                    auto-select-first
                    color="deep-purple"
                    label="*Version"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="newCossaVersion()"
                  :disabled="!form || loading"
                  color="primary"
                >
                  SAVE
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
    </div>
  </template>

<script>
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'

export default {
  props: {
    showNewCossaVersionDialog: { type: Boolean, default: false }
  },

  data: () => ({
    loading: false,
    form: true,
    cossaVersionPayload: {
      version: ''
    },
    rules: {
      required: (value) => !!value || 'Required.'
    }
  }),

  methods: {
    newCossaVersion () {
      if (this.$refs.newCossaVersionForm.validate()) {
        this.loading = true
        apiService
          .post('admin/cossa_version', this.cossaVersionPayload)
          .then((res) => {
            this.loading = false
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'New cossa version added successfully'
            })
            this.$emit('fetchCossaVersion')
            this.$store.dispatch('setNonNotifiedCossaVersion', res.data.new_version.id)
            this.close()
          })
          .catch((err) => {
            this.loading = false
            console.log('Error while adding new cossa version: ', err.response)
            Swal.fire({
              icon: 'error',
              title: 'Failed get cossa version',
              text: err.response.data.message
            })
          })
      }
    },
    close () {
      this.$emit('closeNewCossaVersionDialog')
    }
  }
}
</script>

  <style>
  </style>
